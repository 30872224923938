<template>
  <div class="page">
    <nav-top :index="-1"></nav-top>
    <div class="crumbs-info">
      <div class="crumbs-search">
        <div class="crumbs">
          <div class="item">
            <router-link to="/">首页</router-link>
            <span class="right-arrows"></span>
          </div>
          <div class="item active">
            <router-link to="#">个人中心</router-link>
          </div>
        </div>
        <div class="search">
          <div class="search-text">
            <div class="text">
              <input type="text" placeholder="请输入关键词" v-model="keyword">
            </div>
            <div class="btn" @click="handleSearchClick">
              <img src="/static/images/fdj_ico2.png" alt="">
            </div>
          </div>
          <div class="report-btn" @click="handleReportClick">
            <span>快速报备</span>
          </div>
        </div>
      </div>
    </div>
    <div class="my-info">
      <div class="all-my">
        <div class="title">
          <span>个人中心</span>
          <span>/ PERSONAL CENTER</span>
        </div>
      </div>
      <div class="my-data">
        <div class="my-item">
          <div class="user-info">
            <div class="pic">
              <img :src="info.avatarUrl" alt="">
            </div>
            <div class="name">
              <span>{{ info.nickName }}</span>
            </div>
          </div>
          <router-link to="/MessageList" class="item" :class="{'active':routeName==='MessageList'}">
            <div class="icon">
              <img src="/static/images/my_mess_on.png" alt="" v-show="routeName==='MessageList'">
              <img src="/static/images/my_mess_off.png" alt="" v-show="routeName!=='MessageList'">
            </div>
            <div class="title">
              <span>消息管理</span>
            </div>
          </router-link>
          <router-link to="/MyReport" class="item" :class="{'active':routeName==='MyReport'}">
            <div class="icon">
              <img src="/static/images/my_bb_on.png" alt="" v-show="routeName==='MyReport'">
              <img src="/static/images/my_bb_off.png" alt="" v-show="routeName!=='MyReport'">
            </div>
            <div class="title">
              <span>我的报备</span>
            </div>
          </router-link>
          <router-link to="/CommissionRecord" class="item" :class="{'active':routeName==='CommissionRecord'}">
            <div class="icon">
              <img src="/static/images/my_jy_on.png" alt="" v-show="routeName==='CommissionRecord'">
              <img src="/static/images/my_jy_off.png" alt="" v-show="routeName!=='CommissionRecord'">
            </div>
            <div class="title">
              <span>结佣申请记录</span>
            </div>
          </router-link>
          <router-link to="/BrowseRecord" class="item" :class="{'active':routeName==='BrowseRecord'}">
            <div class="icon">
              <img src="/static/images/my_jl_on.png" alt="" v-show="routeName==='BrowseRecord'">
              <img src="/static/images/my_jl_off.png" alt="" v-show="routeName!=='BrowseRecord'">
            </div>
            <div class="title">
              <span>浏览记录</span>
            </div>
          </router-link>
          <router-link to="/CollectHouses" class="item" :class="{'active':routeName==='CollectHouses'}">
            <div class="icon">
              <img src="/static/images/my_sc_ob.png" alt="" v-show="routeName==='CollectHouses'">
              <img src="/static/images/my_sc_off.png" alt="" v-show="routeName!=='CollectHouses'">
            </div>
            <div class="title">
              <span>我的收藏</span>
            </div>
          </router-link>
          <router-link to="/ChangePassword" class="item" :class="{'active':routeName==='ChangePassword'}">
            <div class="icon">
              <img src="/static/images/my_gr_on.png" alt="" v-show="routeName==='ChangePassword'">
              <img src="/static/images/my_gr_off.png" alt="" v-show="routeName!=='ChangePassword'">
            </div>
            <div class="title">
              <span>个人资料</span>
            </div>
          </router-link>
          <div class="out-btn" @click="handleLogoutClick">
            <span>退出登录</span>
          </div>
        </div>
        <div class="my-content">
          <router-view></router-view>
        </div>
      </div>
    </div>
    <footer-bar></footer-bar>
  </div>
</template>

<script>
import NavTop from '../../components/NavTop/NavTop'
import FooterBar from '../../components/FooterBar/FooterBar'

export default {
  name: 'my',
  components: {FooterBar, NavTop},
  data() {
    return {
      loading: false,
      info: {},
      routeName: '',
      keyword: ''
    }
  },
  mounted() {
    this.routeName = this.$route.name
    this.getData()
  },
  methods: {
    async getData() {
      this.loading = true
      try {
        let res = await this.http({
          url: '/api/my/index',
          method: 'GET',
        })
        this.info = res.data.member_info
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    handleSearchClick() {
      this.$router.push({
        path: '/newsList',
        query: {
          keyword: this.keyword
        }
      })
    },
    handleReportClick() {
      this.$router.push({
        path: '/report'
      })
    },
    handleLogoutClick() {
      localStorage.removeItem('MEMBER')
      localStorage.removeItem('MEMBER_ID')
      this.$router.push({
        path: '/'
      })
    }
  },
  watch: {
    '$route': function (val) {
      this.routeName = val.name
    }
  }
}
</script>

<style scoped lang="scss">
@import "my.scss";
</style>
